import { __ } from "./translate";

export const time = {
    methods: {
        toHumanTime(seconds) {
            const d = Math.floor(seconds / 86400);
            const h = Math.floor((seconds - (d * 86400)) / 3600);
            const m = Math.floor((seconds - (d * 86400) - (h * 3600)) / 60);
            const s = Math.floor(seconds - (d * 86400) - (h * 3600) - (m * 60));

            const output = [];

            if (d !== 0) {
                output.push(d + ' ' + __(d === 1 ? 'day' : 'days'));
            }

            if (h !== 0) {
                output.push(h + ' ' + __(h === 1 ? 'hour' : 'hours'));
            }

            if (m !== 0) {
                output.push(m + ' ' + __(m === 1 ? 'minute' : 'minutes'));
            }

            if (s !== 0) {
                output.push(s + ' ' + __(s === 1 ? 'second' : 'seconds'));
            }

            return output.join(' ');
        },
    }
}

export const toHumanTime = time.methods.toHumanTime;
