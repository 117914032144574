import moment from 'moment/min/moment-with-locales';

moment.locale(window._locale);

export const date = {
    methods: {
        toHumanDate(date) {
            return moment.utc(date).local().format('dddd, Do MMMM YYYY, HH:mm');
        },
        toUTCDate(date) {
            return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
        },
        toLocalDate(date) {
            return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
        }
    }
}

export const toHumanDate = date.methods.toHumanDate;
export const toUTCDate = date.methods.toUTCDate;
export const toLocalDate = date.methods.toLocalDate;
