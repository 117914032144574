import { usePage } from "@inertiajs/inertia-vue3";
import { computed } from "vue";

const page = usePage();
const u = computed(() => page.props.value.auth.user);

export const auth = {
    methods: {
        user() {
            return u.value;
        },
        isAdmin() {
            return u.value?.role.id === 'administrator';
        },
        isOrganizer() {
            return isAdmin() || u.value?.role.id === 'organizer';
        },
    }
}

export const user = auth.methods.user;
export const isAdmin = auth.methods.isAdmin;
export const isOrganizer = auth.methods.isOrganizer;
