export const translate = {
    methods: {
        __(key, replacements = {}) {
            let translated = window._translations[key] || key;

            Object.keys(replacements).forEach((replacement) => {
                translated = translated.replace(`:${replacement}`, replacements[replacement])
            });

            return translated;
        }
    }
}

export const __ = translate.methods.__;
