import 'bootstrap';
import '../scss/app.scss';

// Import bootstrap icons
import 'bootstrap-icons/font/bootstrap-icons.min.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import mitt from 'mitt';

// Import mixins
import { translate } from './Mixins/translate';
import { date } from './Mixins/date';
import { auth } from './Mixins/auth';
import { time } from './Mixins/time';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Orientation';

const setTheme = () => {
    let theme = localStorage.getItem('theme') || 'auto';

    if (theme === 'auto') {
        theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light"
    }

    document.body.setAttribute('data-bs-theme', theme);
}

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, app, props, plugin }) {
        const application = createApp({
            created() {
                window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => setTheme());
            },
            mounted() {
                setTheme();
            },
            render: () => h(app, props),
        })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .mixin(translate)
            .mixin(date)
            .mixin(time)
            .mixin(auth);

        application.config.globalProperties.emitter = mitt();

        return application.mount(el);
    },
});

InertiaProgress.init({ color: '#4B5563' });
